<template>
  <div class="liveBroadcast" :ref="'course_chapter' + 0">
    <div v-if="buy == 0" class="lijigoumai"><button style="margin-top: 9px !important;" class="goumaianniu" type="default" @click="goumailist()">立即购买</button></div>
    <!-- 第一部分 -->
    <div class="yuyuyu">
        <van-popup v-model="code" title="" style="border-radius: 10px;width: 310px;height: 230px;"  width="320px"  theme="round-button" showConfirmButton="false" closeable>
        <h3 style="text-align: center;" class="kechengduihuan">课程兑换码</h3>
      <p class="huoqulist">兑换码请联系相关老师领取</p>
      <form label-width="120px" style="margin-top: 20px;">
        <div style="width: 250px;height: 40px;margin: 0 auto;text-align: center;">
          <input style="text-align: left;" class="codeshuru" v-model="codeData" placeholder="请输入课程兑换码" />
          <div style="height: 20px;text-align: left;"><p class="yiguoqilist">{{this.errData}}</p></div>
        </div>
      </form>
      <div style="text-align: center;margin-top: 5px;">
        <button class="lijiduan" type="text" @click="useCdk()">立即兑换</button>
      </div>
    </van-popup>
      </div>
    <div class="headerBox">
      <div class="diyi">
        <img v-show="!playStatus" class="courseCover" :src="cover" />
        <template >
          <SHPlayer
            ref="cameraVideo"
            videoType="vod"
            @timeupdate="handleListenCurTimeChangeCameraVideo"
            :key="1"
            :playerType="playerType"
            class="cameraVideo" 
            @play="handlePlay"
            :showRate="false"
            v-if="isThreeScreenCourse"
            @pause="handlePause"
            @seeking="handleSeek"
            @loadstart="onLoadstart"
            @loadeddata="onLoadeddata"
            :isMutedReal="isMuted"
            :fans_id="fans_id"
            :runObj="runObj" :copyrightObj="copyrightObj" :nick="nick"
            />
        </template>
        <template >
          <SHPlayer
            videoType="vod"
            ref="mainVideo"
            :key="2"
            :playerType="playerType"
            v-if="!isThreeScreenCourse"
            @timeupdate="handleListenCurTimeChange"
            @play="onPlayerPlay"
            @pause="onPlayerPause"
            @ended="onPlayerEnded"
            @loadstart="onLoadstart"
            class="main-video"
            :fans_id="fans_id"
             :runObj="runObj" :copyrightObj="copyrightObj" :nick="nick"
             :subtitles="videoSubtitlesContents"
              :openSubtitles="subtitlesStatus"
              :subtitleOptions="{ bottom: '5px', fontSize: '14px', padding: '4px 8px' }"
             />
          <!-- <video
            ref="tfPlayer"
            id="tfPlayer"
            autoplay
            :videoPoster="cover"
            :VODData="VODData"
            :playType="'vod'"
            class="tfPlayer"
          /> -->
        </template>
        
        <div style="height: 90px; width: 100%">
          <div>
            <img
              class="fanhui"
              @click="goOff()"
              style="margin-top: 5px"
              src="../assets/images/fanhuizhong.png"
            />
          </div>
        </div>
      </div>

      <template >
        <SHPlayer
          videoType="vod"
          class="white-board"
          :key="3"
          v-if="isThreeScreenCourse"
          :playerType="'tcPlayer'"
          :showControls="false"
          ref="whiteBoard"
          @loadstart="onLoadstart"
          @play="onPlayerPlay"
          @pause="onPlayerPause"
           @toggle-mute="handleToggleMute"
          @ended="onPlayerEnded"
          :fans_id="fans_id"
          :runObj="runObj" :copyrightObj="copyrightObj" :nick="nick" :isThird="false"
          :subtitles="videoSubtitlesContents"
          :openSubtitles="subtitlesStatus"
          :subtitleOptions="{ bottom: '5px', fontSize: '14px', padding: '4px 8px' }"
          />
      </template>

      <div class="debf">
        <p class="fafa">{{ name }}</p>
        <div>
          <div class="zhibosj">
            直播时间：{{ start_timeList.start_time }}
            <div v-if="numStatus == 1" class="rightStudy">
              <img src="../assets/img/guankan.png" />
              <span class="qishi">{{ studyNum }}人学习</span>
            </div>
          </div>
          <div class="mytestping" v-if="comment">我的评测：{{comment}}</div>
          <div style="display: flex;margin-bottom: 8px;">
            <div v-if="discount_price == null && buy == 0" class="mofangzhehou">¥{{price}}</div>
            <div v-if="discount_price != null && buy == 0" class="mofangzhehou">¥{{discount_price}}</div>
            <div v-if="discount_price != null && buy == 0" class="mofangzheqian">¥{{price}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="contentBox">
      <div class="lineBox"></div>
      <div class="dsanbf">
        <div class="dsanbf_header">
          <div>
            <p style="float: left" :class="[{ 'active': tabIndex === 1 },'dsanbf_title']" @click="tabChange(1)">课程目录</p>
            <p style="float: left" :class="[{ 'active': tabIndex === 2 },'dsanbf_title']" @click="tabChange(2)" v-show="subtitlesStatus">视频文稿</p>
          </div>
          <div class="banji" @click="showClass = true">
            切换班级: {{ currClassName }}
            <van-icon
              v-if="showClass == false"
              style="margin-left: -3px"
              name="arrow"
            />
            <van-icon
              style="margin-left: -3px"
              v-else-if="showClass == true"
              name="arrow-down"
            />
          </div>
          <!-- <div style="clear: both"></div> -->
        </div>
        <VideoDraft ref="videoDraft" v-show="tabIndex === 2&&subtitlesStatus" :subtitles="videoSubtitlesContents" @updatePlayerTime="updatePlayerTime" :isSelectVideo="isSelectVideo"/>
        <van-collapse
          v-show="tabIndex === 1"
          style="margin-top: 0.43rem; padding: 0 0.43rem"
          v-model="activeNames"
          :border="false"
        >
          <van-collapse-item
            class="zhangjielist"
            :id="'zhangjielist' + item.sectionId"
            :ref="'collapseId' + item.sectionId"
            v-for="item in optionsa"
            :key="item.id"
            :title="item.sectionName"
            :name="item.sectionId"
          >
            <div
              style="margin-top: 0.43rem"
              v-for="(item1, index) in item.chapters"
              :key="item1.id"
              :ref="'chapterJie' + item1.id"
            >
              <div class="liveInformation">
                <span class="linyi">{{
                  index > 8 ? index + 1 : "0" + (index + 1)
                }}</span>
                <span v-if="item1.type === 3" class="huifanga">回放</span>
                <span v-if="item1.type === 2" class="huifanga">录播</span>
                <span
                  v-else-if="
                    item1.type === 4 || (item1.type === 1 && item1.status !== 3)
                  "
                  class="huifangb"
                  >直播</span
                >
                <span
                  v-if="item1.status !== 3"
                  class="chushia"
                  @click="showMore(item1.id)"
                  :class="{ show: id == item1.id }"
                  >{{ item1.name }}</span
                >
                <span v-else-if="item1.status === 3" class="chushialist">{{ 
                  item1.name
                }}</span>
                <span v-if="item1.show == 1" class="keduihuan">可兑换</span>
              </div>
              <div style="margin-top: 4px">
                <span class="shijiand">{{ item1.start_time }}</span>
                <div
                  v-if="item1.status === 4 || item1.status === 2"
                  @click="liveAuth(item1, item1.show)"
                  class="jirzb"
                >
                  进入直播
                </div>
                <div v-if="item1.status === 3" class="jirzblist">
                  直播已结束
                </div>
              </div>

              <div
                v-if="item1.videos.length > 3 && item1.type === 3"
                class="sort"
              >
                <div
                  :class="sortimgs[item1.id] ? 'stbx stbx1' : 'stbx'"
                  @click="sorta(item1.id)"
                >
                  {{
                    sortform[item1.id] == 1
                      ? "升序"
                      : sortform[item1.id] == 0
                      ? "降序"
                      : "排序"
                  }}<img
                    class="sortimg"
                    v-if="!sortimgs[item1.id]"
                    src="@/assets/images/sort.png"
                    alt=""
                  /><img
                    v-else
                    class="sortimg"
                    :src="sortimgs[item1.id]"
                    alt=""
                  />
                </div>
              </div>
              <div
                @click="liveAutha(item, item1, item2)"
                v-for="item2 in item1.videos"
                :key="item2.id"
              >
                <div class="videoItem">
                  <div class="videoInformation">
                    <img class="playBtn" src="../assets/images/bofang.png" />
                    <span
                      :class="acterss == item2.id ? 'active' : ''"
                      class="chushia1"
                      >{{ item2.name }}</span
                    >
                  </div>
                  <div class="time">
                    <span style="margin-right: 5px; margin-left: 25px">{{
                      item2 && transformation(item2.duration)
                    }}</span>
                    <span class="aglook" v-if="item2.is">上次看到</span>
                  </div>
                </div>
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <div
          v-if="showAll"
          @click="openAll"
          style="text-align: center; margin-top: 20px"
        >
          <span class="gengduo">查看更多目录</span>
          <img
            style="margin-left: 6px; width: 14px; vertical-align: -2px"
            src="../assets/img/xiangxiazhankai.png"
          />
        </div>
      </div>
      <div class="lineBox"></div>
      <div class="dsibf">
        <p class="mulu">课程介绍</p>
        <div style="background: #fff; height: 100%">
          <p style="margin: 20px 17px" class="kecheng_desc" v-html="desc"></p>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showClass"
      position="bottom"
      round
      :style="{ height: '50%' }"
    >
      <div>
        <p class="xuanban">请选择班级</p>
        <img
          @click="guanbiclassget()"
          class="guanbiclass"
          src="../assets/images/guanbilist.png"
          alt=""
        />
        <div
          v-for="item in options"
          :key="item.period"
          @click="classChange(item)"
        >
          <p
            class="luse"
            :style="{ color: item.select ? '#10A675' : '#000000' }"
          >
            {{ item.period }}
          </p>
          <div v-if="item.select" class="xuanzhong">
            <img
              style="height: 20px"
              src="../assets/images/xinzhuangjiehe.png"
              alt=""
            />
          </div>
          <div v-else class="xuanzhong">
            <img src="../assets/images/weixuanertu.png" alt="" />
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { ActionSheet, Toast, Popup } from "vant";
import SHPlayer from 'shplayer';
import { mapGetters } from "vuex";
import Vue from "vue";
import { ref } from "vue";
import { Dialog } from 'vant';
import VideoDraft from '@/components/videoDraft.vue'
Vue.use(Dialog);
Vue.use(Popup);
Vue.use(Toast);
Vue.use(ActionSheet);
export default {
  name: "liveBroadcast",
  computed: {
    ...mapGetters({
      org_id: "user/getOrgId",
      fans_id : "user/getFansId",
    })
  },
  data() {
    return {
      playerType: 'tcPlayer',
      is_enable_caption:2,
      isSelectVideo:false,
      subtitlesStatus: false,
      videoSubtitlesContents: [],
      tabIndex: 1,
       runObj: {
        open: false,
        fontSize: "",
        fontColor: "",
        fontTrans:1
      },
      copyrightObj: {
        open: false,
        inputValue: "",
        copyrightTrans:1
      },
      nick:'',
      isMuted:false,
      sortimgs: {},
      sortform:{},
      acterss:  null,
      domain: "test.tenghuiwangxiao.cn",
      name: "",
      desc: "",
      options: [],
      value: "",
      optionsa: [],
      optionsaAll: [],
      class_id: "",
      showAll: false,
      optionsaAlla: [],
      start_timeList: "",
      studyNum: 0,
      vodDomduration:'',
      numStatus: "",
      show: false,
      showClass: false,
      currClassName: "",
      cover: "",
      VODData: null,
      activeNames: [],
      // playerType: "vod",
      currentPlayerData: null,
      heartBeat:null,
      playStatus:'',
      vodDom:null,
      scrollTop:'',
      fileId:'',
      wx_h5_url:"https://u.shkt.online",
      currentSectionId:'',
      currentVideoId:'',
      sectionVideoList:[],
      comment:"",
      isFirst:true,
      innerItems:{},
      cameraVideoUrl: '',
      whiteBoardVideoUrl: '',
      remainingTime: 0,
      code:false,
      codeData:"",
      codeArr:[],
      errData: '',
      price: '',
      discount_price: '',
      buy: '',
      licenseUrl:'https://license.vod2.myqcloud.com/license/v2/1317978474_1/v_cube.license',
      cameraVideoPlayer:null,
      whiteBoardPlayer:null,
      tfPlayer:null,
      isThreeScreenCourse:false,
      playParams:{},
      whiteBoardPlayParams:{},
      cameraVideoPlayParams:{},
      memoryPoints:0,
      playType:'',
      whiteBoardInitOptions:{
        bigPlayButton: false,
        muted: true,
        controlBar: false
      },
      cameraVideoInitOptions:{
        controlBar:{
          playbackRateMenuButton:false
        },
        bigPlayButton: false
      },
      showPlayIcon:false,
      chapter_id:'',
      canPlayH265: false
    };
  },
  created() {
    const url = `https://shkt-online-live-1317978474.cos.accelerate.myqcloud.com/web/config/playType.json?id=${new Date().valueOf()}`
    fetch(url).then(res => res.json()).then(res => this.canPlayH265 = res.canPlayH265)
    this.getCourseInfo();
    this.listClass();
    this.getUserInfo()
    this.getPlayerH5URL()
    // this.infolist()
  },
  mounted(){
    this.getRun()
    this.vodDom = document.getElementById('tfPlayer')
    let myvideo = this.vodDom 
    // 监听时间变化
    this.handleListenCurTimeChange(myvideo)

    if(this.$route.query.sectionId){
      this.playHistoryVideo()
    }
    //监听关闭网页
    window.addEventListener('beforeunload', e => this.historycallback(), false)
  },
  watch: {
    optionsa(to) {
      if (to.length > 0) {
        this.$nextTick(() => {  
        //
          if (this.$refs.collapseId0 && this.$refs.collapseId0[0] && this.isFirst &&!this.$route.query.sectionId) {
            this.$refs.collapseId0[0].toggle(true);
          }else{
            // console.log(,'this.$refs.collapseId0[0]');
      // console.log(this.$route.query.sectionId,'document.getElementById');
            if (this.$route.query.sectionId) {
              this.activeNames.push(Number(this.$route.query.sectionId))
              this.scrollTop = document.querySelector(".liveBroadcast");

            this.scrollTop.scrollTop=this.$refs['collapseId'+this.$route.query.sectionId][0].$el.offsetTop
            console.log(this.scrollTop ,'scrollTop');
            console.log(this.$refs['collapseId'+this.$route.query.sectionId][0].$el.offsetTop,'scrollTops');
            //  console.log(this.$refs['collapseId'+this.$route.query.sectionId][0].$el,'this.$refs.$el.offsetTop');
                // console.log(this.$route.query.sectionId,'this.isFirst2');
            }
          }
        })
      }
    }
  },
  destroyed(){
   
      //  console.log(document.getElementById('id_video_vod_tengface'),'kfcl');
  },
  beforeDestroy(){

    window.removeEventListener('beforeunload', e => this.historycallback(), false)

    this.historycallback()

    clearInterval(this.heartBeat)
  },
  components: {
    SHPlayer,
    VideoDraft
  },
  methods: {
      /**
     * 描述：更新播放器时间
     * @param   : val {number} 字幕开始时间
    **/
    updatePlayerTime(val) {
      if(this.isThreeScreenCourse) {
        // 三分屏设置摄像头播放器时间
        // this.$refs.whiteBoardDiv && this.$refs.whiteBoardDiv.setCurrentTime(val)
        this.$refs.cameraVideo && this.$refs.cameraVideo.setCurrentTime(val)
      } else {
        this.$refs.mainVideo && this.$refs.mainVideo.setCurrentTime(val)
      }
    },
     /**
     * 描述：获取视频字幕
    **/
    getVideoSubtitlesContent(id) {
      console.log('id: ', id);
        this.$subApi.post('/caption/getCaptionContentList', {
          // file_id: '1253642695935868212'
          file_id:id
        }).then((res) => {
          if(res?.data?.code !== 200) {
            this.$message.error('获取字幕内容失败')
            return
          }
          this.videoSubtitlesContents = res?.data?.data
          if(this.subtitlesStatus){
            this.tabIndex = 2
            this.isSelectVideo = true
          }
          return
        })
    },
    tabChange(index) {
      this.tabIndex = index
    },
     getRun(){
      this.$api.post("/stu/courseFunctionList", {course_id:this.$route.query.setid,org_id: this.org_id,}).then((res) => {
        console.log(res,'copyrightObjcopyrightObjcopyrightObjcopyrightObjcopyrightObj');
        if (res.data.code === 200) {
          let data=res.data.data
          data.forEach(item=>{
            if(item.module=='copyright_statement'){
              if(item.params){
                console.log('llllllllllllllllllllll');
                let josnP=JSON.parse(item.params)
                  this.copyrightObj={
                    open: item.enabled=='yes'?true:false,
                    inputValue: josnP.inputValue,
                    copyrightTrans: josnP.copyrightTrans/100
                }
                console.log(this.copyrightObj,'copyrightObjcopyrightObjcopyrightObjcopyrightObjcopyrightObj');

              }
            }else if(item.module=='running_horse'){
              if(item.params){
                let josnP=JSON.parse(item.params)
                this.runObj={
                    open: item.enabled=='yes'?true:false,
                    fontSize: josnP.fontSize,
                    fontColor: josnP.fontColor,
                    fontTrans: josnP.fontTrans/100
                }
                console.log(this.runObj,'runObjrunObjrunObjrunObjrunObjrunObjrunObjrunObjrunObjrunObjrunObjrunObjrunObj');
              }
            }
          })
        }
     })

    },
      handleToggleMute(isMuted) {
      // 根据子组件传递过来的 isMuted 值来关闭静音
      this.isMuted=isMuted
    },
    playHistoryVideo(){
      //本地历史时间记录
      let shipingID = localStorage.getItem('shipingID')
      let localViewTime =  localStorage.getItem('currentTime' + shipingID)
      const {sectionId,video_id,view_time,name,chapter_id,class_id,setid:course_id}=this.$route.query
      const section =  {
        sectionId
      }
      const chapter = {
        class_id,
        course_id,
      }
      const video = {
        id:video_id,
        name,
        view_time:view_time?view_time:localViewTime,
        chapter_id
      }
       this.liveAutha(section,chapter,video)
    },
    // 定位播放时间
    handleListenCurTimeChange(val){
        let shipingID = localStorage.getItem('shipingID')
        const currentTime = val;
        localStorage.setItem('currentTime' + shipingID, currentTime)
      this.$refs.videoDraft && this.$refs.videoDraft.setCurrentTime(val)

    },    
    handleListenCurTimeChangeCameraVideo(val){
      this.$refs.videoDraft && this.$refs.videoDraft.setCurrentTime(val)
    },
    // iphone11之前的型号判断
    isIphoneBefore11() {
      if (typeof window !== "undefined" && window.navigator) {
        return /iphone/gi.test(window.navigator.userAgent) && window.screen.height <= 812 || (window.screen.height === 896 && window.screen.width === 414);
      }
      return false;
    },
    singlePlayVideo(options, refName) {
      this.$nextTick(() => {
        this.$refs[refName].setPlayerParams(options)
        this.$refs[refName].play()
      })
    },
    playOneVideo(options, fileIDOption, original) {
      const mainVideo = 'mainVideo'
      if (this.playerType === 'cPlayer') {
        // h265只播url
        this.singlePlayVideo(options, mainVideo)
      } else {
        // h264播放 original===1播Url, 不然播fileId
        if (original === 1) {
          this.singlePlayVideo(options, mainVideo)
        } else {
          this.singlePlayVideo(fileIDOption, mainVideo)
        }
      }
    },
    playThreeVideo(options, sOptions, fileIDOption, sFileIDOption, original, sOriginal) {
      // 三分屏 判断H265
      const cameraVideo = 'cameraVideo'
      if (this.playerType === 'cPlayer') {
        this.singlePlayVideo({...options,...this.cameraVideoInitOptions}, cameraVideo)
      } else {
        // h264播放
        if (original === 1) {
          // 播Url
          this.singlePlayVideo({...options,...this.cameraVideoInitOptions}, cameraVideo)
        } else {
          // 播fileId
          this.singlePlayVideo({...fileIDOption, ...this.cameraVideoInitOptions}, cameraVideo)
        }
      }
      // 三分屏 白板只播放264视频
      const whiteBoard = 'whiteBoard'
      if (sOriginal === 1) {
        // 优先播放Url
        this.singlePlayVideo({...sOptions,...this.whiteBoardInitOptions}, whiteBoard)
      } else {
        this.singlePlayVideo({...sFileIDOption,...this.whiteBoardInitOptions}, whiteBoard)
      }
    },
    // 处理视频播放
    handlePlayVideo(item, new_video) {
      const {
        mp4Url,
        sMp4Url,
        file_id,
        appid,
        sign,
        s_appid,
        s_file_id,
        s_sign,
        original,
        s_original
      } = item;
      // h265逻辑
      let h265Obj = []
      if (new_video && new_video.length) {
        h265Obj = new_video.filter(item => item.vcodec == 2);
      }
      // 找出数组中size最小的值
      const minSize = Math.min(...h265Obj.map(item => item?.size));
      const m265Obj = h265Obj.find(ele => Number(ele?.size) === minSize)
      // 对视频地址做区分
      if (!this.isIphoneBefore11() && this.canPlayH265 && window.Worker && window.WebAssembly) {
        this.playerType = h265Obj.length? 'cPlayer' : 'tcPlayer'
      }
      let videoUrl = this.playerType === 'tcPlayer'? mp4Url : m265Obj?.url // 主视频
      this.fileId = file_id;
      this.playStatus = 2;
      // 播放器创建流程
      const options = {
          sources:[videoUrl],
          playType: "url",
          startTime:this.memoryPoints
      }

      const sOptions = {
        sources:[sMp4Url],
        playType: "url",
        muted: true,
        startTime:this.memoryPoints
      }

      const fileIDOption = {
        fileID: file_id,
        appID: appid,
        psign: sign,
        playType: 'file_id',
        startTime:this.memoryPoints
      }

      const sFileIDOption = {
        fileID: s_file_id,
        appID: s_appid,
        psign: s_sign,
        playType: 'file_id',
        muted: true,
        startTime:this.memoryPoints
      }

      if ((videoUrl && threeVideoUrl) || (file_id && s_file_id)) {
        // 三分屏
        this.showPlayIcon = true;
        this.isThreeScreenCourse = true;
        this.playThreeVideo(options, sOptions, fileIDOption, sFileIDOption, original, s_original)
      } else {
        // 单个视频
        this.isThreeScreenCourse = false;
        this.playOneVideo(options, fileIDOption, original)
      }
    },
    goumailist() {
      const { platform } = this.$route.query
      this.$router.push({path:'/shangpingxq', query:{kechengidzf: this.$route.query.goodsId, platform}})
      // window.location.href = 'https://u.shkt.online/pay/?money=1&opid=70'
    },
    // infolist() {
    //   const data = {
    //     id: 134,
    //     ds_id: this.org_id
    //   }
    //   this.$liveApi.post('/liveapi/api/goods/info', data)
    //   .then(res=>{
    //     if(res.data.code == 200) {
    //       console.log('12444444=========', res.data.data.discount_price)
    //       this.price = res.data.data.price
    //       this.discount_price = res.data.data.discount_price
    //     }
    //   })
    // },
    handleScroll(){
        //获取元素高度  this.$refs.init.offsetHeight
        this.scrollTop = document.querySelector(".liveBroadcast");
        // this.scrollTop.scrollTop=1300
        console.log(this.scrollTop.scrollTop);
      },
      historycallback(){
      if(!this.playStatus) return
      let curTime = ''
      if(this.isThreeScreenCourse){
        curTime = this.$refs.cameraVideo.getCurrentTime()
      }else{
        curTime = this.$refs.mainVideo.getCurrentTime()
      }
      this.curChooseVideo.view_time=curTime
      if (Object.entries(this.curChooseVideo).length>7) {
        this.$api.post("/user/video/callback", this.curChooseVideo)
      }
    },
    historycallbacks(){
      if(!this.playStatus) return
      let curTime = ''
      if(this.isThreeScreenCourse){
        curTime = this.$refs.cameraVideo.getCurrentTime()
      }else{
        curTime = this.$refs.mainVideo.getCurrentTime()
      }
      this.curChooseVideo.view_time=curTime
      var data = new FormData();
      data.push(this.curChooseVideo)
      Navigator.sendBeacon("https://online.tenghuiwangxiao.cn/user/video/callback",data)
    },
    async sorta(ind){
      // console.log(ind);
      let char_sort=''
      if (!this.sortform[ind]) {
        this.sortform[ind] =0
      }
      this.sortform[ind]++
      if (this.sortform[ind]==1) {
        this.sortimgs[ind]=require('@/assets/images/sorts.png')
        char_sort='asc'

      }else if(this.sortform[ind]==2){
        this.sortimgs[ind]=require('@/assets/images/sortx.png')
        this.sortform[ind] =0
        char_sort='desc'
      }
      console.log(this.sortform,'ind');
      await this.getClassCode()
      this.listSections(char_sort)
    },
    async getPlayerH5URL(){
            try {
                let data =  await this.$api.get('https://shanhu-live-user-1317978474.cos.accelerate.myqcloud.com/web/config/miniConfig.json?a='+ new Date().valueOf())
                if (data && data.data && data.data.wx_h5_url) {
                    this.wx_h5_url = data.data.wx_h5_url;
                }      
            } catch (error) {
                console.error(error)
            } 
            
            try {
              let data2 =  await this.$api.get('https://tengfaceliveuser.tengface.net/web/config/userConfig/'+ this.org_id +'.json?a='+ new Date().valueOf())
                if (data2 && data2.data && data2.data.wx_h5_url) {
                    this.wx_h5_url = data2.data.wx_h5_url;
                }
            } catch (error) {
              console.error(error)
            }
       },
    onPlayerEnded() {
      if (this.sectionVideoList.length > 0) {
        
            let sectionListVideo = this.sectionVideoList.find(item=>{
              return item.sectionId == this.currentSectionId;
            })
            // console.log('4343434334', sectionListVideo)
            if (sectionListVideo) {
                 let vidoes = sectionListVideo.video
                  
                let index = vidoes.findIndex(item1=>{
                    return item1.id == this.currentVideoId;
                    
                 })
                 if (index > -1 && index  < vidoes.length-1 ) {
                     let voideItem = vidoes[index + 1];
                     console.log('4343434334', voideItem)
                     this.liveAutha(sectionListVideo,{show:2},voideItem)
                 } 
            }
       }
      // this.currentSectionId
       
       console.log('333333333333')
    },
    onPlayerPlay(){
      this.playStatus = 2
    },
    onPlayerPause(){
      this.playStatus = 3
      this.$refs.videoDraft && this.$refs.videoDraft.clearTimer()

    },
    onLoadstart(){
      // 存在参数，跳转播放记忆点
      this.playStatus = 2
      // if(this.$route.query.sectionId){
        setTimeout(()=>{
          if(this.isThreeScreenCourse){
            this.$refs.cameraVideo.setCurrentTime(this.memoryPoints)
            this.$refs.whiteBoard.setCurrentTime(this.memoryPoints)
          }else{
            this.$refs.mainVideo.setCurrentTime(this.memoryPoints)
          }
        },1000)
      // }
    },
    onLoadeddata(){
      this.playStatus = 2
    },
    guanbiclassget() {
      this.showClass = false;
    },
    goOff() {
      this.$router.go(-1);
    },
    openAll() {
      this.showAll = false;
      this.optionsa = this.optionsaAll;
    },
    async classChange(item) {
      this.tabIndex=1
        this.videoSubtitlesContents=[]
        this.isSelectVideo=false
      console.log(item,'item');
      this.options.forEach((item) => {
        item.select = false;
      });
      item.select = true;
      this.class_id = item.id;
      this.currClassName = item.period;
      this.showClass = false;
      this.historycallback()
      await this.getClassCode();
      this.listSections();
    },
    async getUserInfo(){
       const res = await this.$api.post('/user/index/getUserInfo')
       if(res.data.code === 200){
         this.$store.commit("user/setFansId",res.data.data.id)
         this.$store.commit("user/setOrgId",res.data.data.org_id)
         this.nick=res.data.data.nick_name
       }
    },
    getCourseInfo() {
      // 课程详情
      const data = {
        org_id: this.org_id,
        id: this.$route.query.goodsId,
      };
      if(localStorage.getItem('tengface_fans_id')) {
          data.fans_id = localStorage.getItem('tengface_fans_id')
      }
      this.$liveApi.post("/liveapi/api/goods/info", data).then((res) => {
        if (res.data.code === 200) {
          this.name = res.data.data.name;
          this.desc = res.data.data.desc;
          this.cover = res.data.data.cover;
          this.price = res.data.data.price
          this.buy = res.data.data.buy
          this.discount_price = res.data.data.discount_price
          this.subtitlesStatus=res.data.data.is_enable_caption==1?true:false

        } else {
          Toast.fail(res.data.message)
        }
      });
    },
   async listClass() {
      // 课程班级列表
      const data = {
        org_id: this.org_id,
        course_id: this.$route.query.setid,
      };
    const res=await this.$api.post("/stu/course/listClass", data)
        if (res.data.code === 200) {
          this.options = res.data.data;
          let hasAccessArr = res.data.data.filter((item) => item.access !== 0);
          if (res.data.data && res.data.data.length > 0) {
            if (hasAccessArr.length === 0) {
              let currClassObj = res.data.data[res.data.data.length - 1];
              this.class_id = currClassObj.id;
              this.currClassName = currClassObj.period;
              currClassObj.select = true;
            } else {
              this.class_id = hasAccessArr[0].id;
              this.currClassName = hasAccessArr[0].period;
            }
            if (this.$route.query.class_id) {
              this.class_id = parseInt(this.$route.query.class_id);
            }
            this.options.forEach((item) => {
              if (item.id == this.class_id) {
                item.select = true;
                this.currClassName = item.period;
              } else {
                item.select = false;
              }
            });
            await this.getClassCode();
            this.listSections();
          }
        }
    },
    listSections(char_sort='') {
      // 章节列表
      const data = {
        org_id: this.org_id,
        course_id: this.$route.query.setid,
        class_id: this.class_id,
        page: this.page,
        num: this.num,
        char_sort
      };
      this.$api.post("/stu/course/listSections", data).then((res) => {
        if (res.data.code === 200) {
          this.optionsaAll = res.data.data;
          console.log("sdsdsdsdaafafafasdadss", res.data.data.numStatus);
          if (this.isFirst) {
            // console.log(7777);
            let chapterindex = 0
            let chapterJie = null
            try {
              this.optionsaAll.forEach((item, index) => {
                item.chapters.forEach((item2) => {
                  if(this.codeArr.indexOf(item2.id) != -1 ){
                        item2.show = 1
                      }else{
                        item2.show = 2
                      }
                  if (item2.status == 2) {
                    chapterindex = index
                    chapterJie = item2.id
                    this.showAll = false
                    this.optionsa = res.data.data
                    throw new Error(chapterindex);
                  }
                })
              })
              
            } catch (e) {
              console.log(88888);

              // if (e.message != "End") throw e;
              this.$nextTick(() => {
                console.log(this.isFirst,'this.isFirst1');
                console.log(this.$refs['collapseId' + chapterindex][0],'this.$refs.collapseId2[0]');
                console.log(chapterindex,'chapterindex');

                this.$refs['collapseId' + chapterindex][0].toggle(true);
               
                // this.$refs.collapseId1[0].toggle(true);
                // this.isFirst = false;
                 
                // console.log(!!chapterJie,'!!chapterJie');
                if (this.isFirst && !!chapterJie) {
                                this.isFirst = false;
              console.log(this.isFirst,'this.isFirst4');
                  // console.log(this.isFirst,'this.isFirst3');
                  setTimeout(() => {
                    
                    console.log(12345,'aaa');
                    const parent = this.$refs.course_chapter0
                    // console.log(54321,'bbb');
                    // console.log(chapterJie,'chapterJie');
                    // console.log(this.$refs["chapterJie" + chapterJie],'this.$refs["chapterJie" + chapterJie]11');
                    // console.log(this.$refs["chapterJie" + chapterJie][0],'this.$refs["chapterJie" + chapterJie]22');
                    // console.log(this.$refs["chapterJie" + chapterJie][0].offsetTop,'this.$refs["chapterJie" + chapterJie]33')

                    const position = this.$refs["chapterJie" + chapterJie][0].offsetTop

                    // console.log(parent,'parent');
                    // console.log(position,'position');
                    
                    let lastTop  = chapterindex*44 + position
                    // console.log(lastTop,'aaaaaaaaaaaaaaappp');
                    parent.scrollTo({
                      top: lastTop,
                      behavior: 'smooth'
                    })


                    
                  }, 1000);
                }
              })
            }
            
            if (chapterJie === null) {
              console.log(2333333);
              if (this.optionsaAll.length > 5) {
                this.showAll = this.$route.query.sectionId?false:true
                this.optionsa =this.$route.query.sectionId?this.optionsaAll: this.optionsaAll.slice(0, 5)
              } else {
                this.showAll = false
                this.optionsa = res.data.data
              }
            }

          } else {
            if (this.optionsaAll.length > 5) {
              this.showAll = this.$route.query.sectionId?false:true
              this.optionsa =this.$route.query.sectionId?this.optionsaAll: this.optionsaAll.slice(0, 5)
            } else {
              this.showAll = false
              this.optionsa = res.data.data
            }

          }

          this.classInfo();
          this.tancalsslist = res.data.data
          const list = [];
             
             this.tancalsslist.forEach(item=>{
              let vodes = []
              item.chapters.forEach(item1=>{
                if(this.codeArr.indexOf(item1.id) != -1 ){
                  item1.show = 1
                }else{
                  item1.show = 2
                }
                if(item1.videos.length !== 0){
                   vodes = vodes.concat(item1.videos);
                }
               
              })
               list.push({
                    sectionId:item.sectionId,
                    video:vodes,
                 })
              //  console.log(item.chapters)
             })
             this.sectionVideoList = list;
             console.log('eeeeeeeeeeeee', list)
            
        }
      });
    },
    setup() {
      console.log(555);
      const activeNames = ref(["0"]);
      return { activeNames };
    },
    liveAuth(item1,show) {
      // 直播鉴权
      console.log('111',item1)
      const data = {
        course_id: this.$route.query.setid,
        class_id: this.class_id,
        chapter_id: item1.id,
      };
      this.$api.post("/stu/course/liveAuth", data).then((res) => {
        if (res.data.code === 200) {
          if(item1.type == 4){
            this.fakeLiveJump(item1, res.data)
          }else{
            this.liveAddress(item1, res.data);
          }
        } else {
          if(show == 1){
            this.chapter_id=item1.id
            this.errData = ''
            this.code = true
          }else{
            Toast.fail(res.data.message);
          }
        }
      });
    },

    transformation(duration) {
      let g = duration;
      let m = parseInt((duration / 60) % 60);
      let s = duration % 60;
      if (s <= 9) {
        s = "0" + s;
      }
      if (m <= 9) {
        m = "0" + m;
      }
      if (g >= 3600) {
        g = g / 3600;
        g = parseInt(g);
        g = "0" + g + ":";
      } else {
        g = "";
      }
      return g + m + ":" + s;
    },
    liveAutha(section,chapter,video) {
      this.showPlayIcon = false
      this.curChooseVideo={
        chapter_id:video.chapter_id,
        course_id:chapter.course_id,
        class_id:chapter.class_id,
        video_id:video.id,
        file_id:video.file_id,
        name:video.name,
        duration:video.duration,
        sectionId:section.sectionId
      }
      this.currentSectionId = section.sectionId;
      this.currentVideoId = video.id;
      this.acterss = video.id

      const data = {
        course_id: chapter.course_id?chapter.course_id:this.$route.query.setid,
        class_id: chapter.class_id?chapter.class_id:this.class_id,
        chapter_id: video.chapter_id,
        video_id: video.id,
      }
      this.$api.post("/stu/course/videoAuth", data).then((res) => {
        if (res.data.code === 200) {
          localStorage.setItem('shipingID', video.id)
          this.memoryPoints = video.view_time
          this.handlePlayVideo(res.data.data.video, res.data.data.new_video)
          this.getVideoSubtitlesContent(this.fileId)

        } else {
          if(chapter.show == 1){
            this.chapter_id=video.chapter_id
            this.errData = ''
            this.code = true
          }else{
            Toast.fail(res.data.message);
          }
        }
      });
    },
    startVodTime () {
      clearInterval(this.heartBeat)
      this.heartBeat = setInterval(() => {
        let token = localStorage.getItem('token')
        if (!token) {
          this.heartBeat && clearInterval(this.heartBeat)
        }
        if (this.playStatus == 2 && token) {
          let progress = 0;
          if(this.isThreeScreenCourse){
            progress = parseInt(this.$refs.cameraVideo.getCurrentTime() || 0)
          }else{
            progress = parseInt(this.$refs.mainVideo.getCurrentTime() || 0)
          }
          const data ={
            file_id:this.fileId,
            fans_id:this.fans_id,
            uid:this.org_id,
            progress
          }
          this.$dataApi.post("/dataapi/app/index/videoHeart", data).then(res=>{
            console.log(res)
          })
        }
      }, 5000)
    },
    fakeLiveJump(item1,item) {
      const liveUrl = `${this.wx_h5_url}/f/?ds_id=${item1.ds_id}&tid=${
        item1.room_id
      }&token=${
        item.data.liveToken
      }&other=1&shool_token=${localStorage.getItem('token')}`

      console.log("fakeLiveJump",liveUrl);

      location.href = liveUrl
    },
    liveAddress(item1, item) {
      console.log("item1",item1)
      // 查询直播地址
      if(item1.play_style == 4){
          location.href = `${this.wx_h5_url}/tcicMiddlePage/#/h5?ds_id=${item1.ds_id}&classid=${item1.interact_id}&fans_id=${this.fans_id}`
      }else{
          const liveUrl = `${this.wx_h5_url}/?ds_id=${
            item1.ds_id
          }&token=${
            item.data.liveToken
          }&other=1&shool_token=${localStorage.getItem('token')}`
          console.log("liveAddress",liveUrl);
          location.href = liveUrl;
      }
      

      // const data = {
      //   chapter_id: id,
      // };
      // this.$api.post("/stu/course/liveAddress", data).then((res) => {
      //   if (res.data.code === 200) {
      //     const url = `${res.data.data.live_url}&token=${item.data.liveToken}&shool_token=${localStorage.getItem('token')}&other=1`;
      //     location.href = url;
      //   }
      // });
    },
    classInfo() {
      // 查询班级信息
      const data = {
        org_id: this.org_id,
        classId: this.class_id
      }
       this.$api
        .post('/stu/course/classInfo', data)
        .then((res) => {
          if (res.data.code === 200) {
            if(res.data.data.chapter){
              this.start_timeList = res.data.data.chapter.start_time
            }
            this.numStatus = res.data.data.numStatus
            this.studyNum = res.data.data.studyNum
            this.comment  = res.data.data.comment
          }
        })
    },
    handlePlay() {
      if(this.$refs.cameraVideo && this.$refs.whiteBoard){
        this.playStatus = 2
        this.showPlayIcon = false
        this.$refs.whiteBoard.play()
      }
    },
    handlePause() {
      if(this.$refs.cameraVideo && this.$refs.whiteBoard){
        this.playStatus = 3
        this.$refs.whiteBoard.pause()
      }
      this.$refs.videoDraft && this.$refs.videoDraft.clearTimer()

    },
    playVideo(){
      this.$refs.whiteBoard.setMuted()
      this.$refs.cameraVideo.play()
      this.$refs.whiteBoard.play()
      this.showPlayIcon = false
    },
    handleSeek(val){
      if(this.$refs.cameraVideo && this.$refs.whiteBoard){
        if(this.remainingTime === 0) {
          this.compareVideoTime()
        }
        this.$refs.whiteBoard.setCurrentTime(this.remainingTime + val)
      }
    },
    compareVideoTime() {
      const cameraTime = this.$refs.cameraVideo.getDuration
      const courseTime = this.$refs.whiteBoard.getDuration
      this.remainingTime = (courseTime - cameraTime) || 0
    },
    // 获取当前课程有兑换码章节
   async getClassCode() {
      const data = {
        org_id: this.org_id,
        class_id: this.class_id,
        course_id: this.$route.query.setid,      
      }
     const res=await this.$api.post('/stu/course/getClassCode', data)
        if (res.data.code === 200) {
          const cdarr = res.data.data;
          this.codeArr = Object.values(cdarr);
        }
    },

    // 兑换码兑换课程
    useCdk() {
      const data = {
        org_id: this.org_id,
        code : this.codeData,
        class_id: this.class_id,
        course_id: this.$route.query.setid,
        chapter_id:this.chapter_id
      }
      this.$api.post('/stu/course/useCdk', data).then((res) => {
        console.log(res.data.code)
        if (res.data.code === 200) {
            this.code = false
            this.codeData = ''
            Toast('兑换成功');
          } else if (res.data.code === 400) {
            this.errData = res.data.message
          }
      })
    },
  }
}
</script>
<style scoped>
.goumaianniu {
  /* top: 0;
  left: 0; */
  border: none;
  width: 343px;
  height: 40px;
  background: #ff571f;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFangSC-Regular;
  color: #ffffff;
}
.lijigoumai {
  text-align: center;
  bottom: 0;
  position: fixed;
  width: 100%;
  height: 58px;
  /* line-height: 58px; */
  background: #fefffe;
  box-shadow: 0px -3px 10px 0px rgba(0,0,0,0.05); 
}
.mofangzheqian {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-decoration:  line-through;
  color: #939393;
  margin-top: 15px;
  margin-left: 7px;
}
.mofangzhehou {
  font-size: 18px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  color: #ff3700;
  margin-top: 10px;
  margin-left: 16px;
}
 .yuyuyu >>> .el-dialog {
  border-radius: 20px;
 }
 .kechengduihuan {
  font-size: 20px;
  font-family: PingFangSC-Medium;
  text-align: center;
  color: #202020;
  margin-bottom: 5px;
  margin-top: 30px;
 }
 .huoqulist {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #666;
  margin-top: 1px;
 }
 .yuyuyu >>> .el-input__inner {
  background: #f5f5f5;
border-radius: 4px;
width: 244px;
height: 36px;
border: none;
 }
 .lijiduan {
  width: 250px;
  height: 40px;
  background: linear-gradient(99deg,#73aaff 0%, #0053ff 100%);
  border-radius: 6px;
  margin-top: 16px;
  border: none;
  color: #fff;
 }
 .keduihuan{
  width: 38px;
  height: 16px;
  background: #ffece7;
  border-radius: 2px;
  font-size: 10px;
  font-family: PingFangSC-Regular;
  text-align: left;
  color: #f33f27;
  line-height: 14px;
  margin-left: 5px;
  padding: 2px;
 }
 .codeshuru{
  width: 250px;
  height: 40px;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 0 10px;
  box-sizing: border-box;
 }
 .yiguoqilist {
  font-size: 10px;
  font-family: PingFangSC-Regular;
  text-align: left;
  color: #ff5227;
  line-height: 20px;
  padding-left: 10px;
  margin: 0;
 }
 .yuyuyu >>> .el-dialog--center .el-dialog__body {
  padding: 25px 25px 0px;
 }
</style>
<style scoped>
.away-top {
  margin-top: 6.43rem !important;
}
.sortimg{
  width: 0.5rem;
  height: 0.5rem;
}
.sort{
  font-size: 0.375rem;
  width: 100%;
  display: flex;
  justify-content: right;
}
.activest{
  color: #1872ed;
}
.stbx{
  width: 1.5rem;
  height: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: solid #B7B7B7 1px;
}
.stbx1{
  color: #10A675;
  background: #E6F6F0;
  border: solid #10A675 1px;
}
.tfPlayer >>> .playBtn{
  width:12% !important;
}
.contentBox >>> .van-cell--clickable {
  background: #f6f6f6;
}
.liveBroadcast .headerBox {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1;
}

.liveBroadcast .contentBox {
  padding-top: 7.90rem;
}

.dsanbf >>> .el-input__inner {
  border: none;
  width: 185px;
}
.banji {
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  float: right;
  /* margin-top: 0.48rem; */
  margin-right: 0.43rem;
  height: 24px;
  line-height: 24px;
  border: #1872ed 1px solid;
  border-radius: 12px;
  padding: 0 8px;
  color: #1872ed;
}
/* 头部 */
.diyi {
  width: 100%;
  height: 5.33rem;
  overflow: hidden;
  background: #fff;
}

.diyi .courseCover {
  z-index: 3000;
  width: 100%;
  height: 100%;
}

.diyi .main-video{
  width: 100%;
  height: 100%;
}
.diyi .cameraVideo {
  width: 100%;
  height: 100%;
}

.white-board {
  width: 100%;
  height: 5.33rem !important;
}

.fanhui {
	width: 25px;
    height: 25px;
    opacity: 0.5;
    background: #000000;
    border-radius: 50%;
    text-align: center;
    top: 10px;
    left: 10px;
    position: absolute;
	z-index: 9999;
}
.fanhuiyi {
  width: 30px;
  height: 30px;
  opacity: 0.5;
  background: #000000;
  border-radius: 50%;
  text-align: center;
  margin-top: 13px;
}
.fanhuier {
  width: 30px;
  height: 30px;
  opacity: 0.5;
  background: #000000;
  border-radius: 50%;
  text-align: center;
  margin-top: 13px;
  margin-left: 18px;
}
.mianfei {
  width: 109px;
  height: 40px;
  opacity: 0.5;
  background: #000000;
  border-radius: 20px;
  clear: both;
  margin: 0 auto;
  /* margin-top: 88px; */
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}

/* 第二部分 */
.debf {
  width: 100%;
  /* height: 77px; */
  background: #fff;
  overflow: hidden;
  margin-top: -8px;
}
.liveBroadcast {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  height: 100%;
}
.liveBroadcast .fafa {
  font-size: 0.48rem;
  line-height: 0.64rem;
  font-weight: 600;
  color: #101010;
  margin-top: 0.27rem;
  margin-left: 0.43rem;
  margin-bottom: 0px;
}
.liveBroadcast .zhibosj {
  margin: 0px;
  margin-top: 0.29rem;
  margin-left: 0.43rem;
  font-size: 0.32rem;
  line-height: 0.43rem;
  font-weight: 400;
  color: #888888;
}
.liveBroadcast .dsanbf .dsanbf_header{
    display: flex;
    align-items: center;
    height: 1rem;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    font-size: 16px;
    color: #666666;
}
.liveBroadcast .dsanbf .dsanbf_header .dsanbf_title{
  height: 1rem;
  line-height: 1rem;
    margin-left: 0.4rem;
}
.liveBroadcast .dsanbf .dsanbf_header .active{
  color:#202020;
}
.liveBroadcast .dsanbf .dsanbf_header .active::after{
  content: '';
  display: flex;
  position: relative;
  top:0;
  left:28%;
  width: 24px;
  height: 2px;
  background: #1872ed;
  border-radius: 2px;
}
.liveBroadcast .mytestping {
  margin: 0px;
  margin-top: 5px;
  padding: 0 0.43rem;
  font-size: 0.32rem;
  line-height: 0.43rem;
  font-weight: 400;
  color: #888888;
}

.liveBroadcast .rightStudy {
  float: right;
  margin-right: 0.43rem;
}

.liveBroadcast .rightStudy img {
  width: 16px;
  height: 16px;
  vertical-align: -0.05rem;
}

.liveBroadcast .qishi {
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  color: #1872ed;
  margin-left: 0.13rem;
}

.liveBroadcast .lineBox {
  width: 100%;
  height: 0.27rem;
  background-color: #f6f6f6;
}

/* 第三部分 */
.liveBroadcast .dsanbf {
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: hidden;
  padding-bottom: 0.53rem;
}
.liveBroadcast .mulu {
  font-size: 0.48rem;
  line-height: 0.59rem;
  font-weight: 600;
  color: #101010;
  margin-top: 0.43rem;
  margin-left: 0.43rem;
  margin-bottom: 0px;
}
.aglook{
  padding: 2px 5px;
  border-radius: 5px;
  color: #528dfb;
  font-size: 12px;
}
.liveBroadcast .courseItemView {
  overflow: hidden;
  margin-top: 0.53rem;
}

.liveBroadcast .linyi {
  font-size: 0.37rem;
  color: #20242f;
  margin-left: -0.27rem;
}

.liveBroadcast .videoItem {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-left: 0.63rem;
  margin-top: 0.43rem;
}

.liveBroadcast .videoItem .playBtn {
  /* width: 0.37rem; */
width:  0.37rem;
height: 0.37rem;
  vertical-align: -0.05rem;
}

.liveBroadcast .videoItem .time {
  min-width: 80px;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.32rem;
  color: #888888;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 0.53rem;
  margin-top: 0.1rem;
  margin-bottom: 0px; */
}

.huifanga {
  background: #d9e8fe;
  border-radius: 2px;
  padding: 2px 3px;
  font-size: 0.32rem;
  font-weight: 400;
  color: #3f8ef8;
  margin-left: 0.45rem;
}
.huifangb {
  background: #e6f6f0;
  border-radius: 2px;
  padding: 2px 3px;
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 0.45rem;
}
.chushi {
  font-size: 0.37rem;
  font-weight: 500;
  color: #10a675;
  margin-left: 6px;
}
.chushia {
  font-size: 0.37rem;
  font-weight: 500;
  color: #101010;
  margin-left: 0.21rem;
}
.chushialist {
  font-size: 0.37rem;
  font-weight: 500;
  color: #101010;
  margin-left: 0.43rem;
}
.shijianb {
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 49px;
}
.shijianc {
  font-size: 0.32rem;
  font-weight: 400;
  color: #10a675;
  margin-left: 10px;
}
.liveBroadcast .shijiand {
  display: inline-block;
  font-size: 0.32rem !important;
  font-weight: 400;
  color: #888888;
  margin-left: 1.28rem;
}
.shijianf {
  font-size: 0.32rem;
  font-weight: 400;
  color: #888888;
  margin-left: 10px;
}
.gengduo {
  font-size: 0.37rem;
  font-weight: 400;
  color: #10a675;
}
/* 第四部分 */
.dsibf {
  width: 100%;
  background: #fff;
  overflow: hidden;
  margin-bottom: 58px;
}

.meiyou {
  width: 100%;
  height: 37px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #888888;
}
.dibu {
  width: 100%;
  height: 58px;
  background: #fefffe;
  box-shadow: 0px -3px 10px 0px rgba(0, 0, 0, 0.05);
}
.liji {
  width: 260px;
  height: 40px;
  background: linear-gradient(90deg, #fdb56b, #fc6d2b);
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 9px;
  margin-left: 31px;
}
.active {
  color: #1872ed;
}
.xuanban {
  font-size: 0.37rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #000000;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 33px;
}
.luse {
  font-size: 0.43rem;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  /* color: #10a675; */
  margin-left: 20px;
  margin-top: 26px;
}
.xuanzhong {
  float: right;
  margin-top: -36px;
  margin-right: 25px;
}
.xuanzhong img {
  width: 0.53rem;
  height: 0.53rem;
}
.jirzb {
  margin-right: -0.33rem;
  float: right;
  background: linear-gradient(95deg, #78d4b6 4%, #0bb67e 95%);
  font-size: 12px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #fefffe;
  padding: 5px 11px;
  border-radius: 14px;
  margin-top: -20px;
}
.jirzblist {
  float: right;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #888888;
  margin-top: -20px;
}
.guanbiclass {
  position: absolute;
  width: 0.37rem;
  height: 0.37rem;
  top: 0.61rem;
  right: 0.4rem;
}
.zhangjielist {
  font-size: 0.43rem;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  color: #20242f;
  margin-top: 0.27rem;
}
.zhangjielist ::v-deep .van-cell__title span {
  display: inline-block;
  font-size: 0.43rem !important;
}
</style>

<style>
.kecheng_desc img {
  width: 100%;
}
</style>
